import React,{Fragment, useState} from 'react'
import axios from "axios";
import {Link, navigate} from 'gatsby'
import Seo from "../../components/seo/seo";
import AOS from "aos";
import TextBox from "../../components/inputs/Textbox";
import PhoneNumber from "../../components/inputs/PhoneNumber";
import CheckBox from "../../components/inputs/CheckBox";
// import SelectBox from "../../components/inputs/SelectBox";
import Loading from "../../components/common/Loading";
import ErrorMessage from "../../components/common/ErrorMessage";
// import countryList from '../../components/json/countryList'
// import industryList from '../../components/json/businessList'
import logo from '../../assets/img/virsat-logo-light.svg'
import { StaticImage } from "gatsby-plugin-image";
// import downloadPDF from "../../components/utils/downloadPDF"

function CourseRegistration({location}) {
  const [sending, setSending] = useState(false);
  const [errors, setErrors] = useState({});
//   const [success, setSuccess] = useState(false);

  React.useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  const [txtValues, setTxtValues] = useState({
    name: "",
    company: "",
    email: "",
    mobile: "",
    course1: 0,
    course2: 0,
    course3: 0,
    course4: 0,
    course5: 0,
    course6: 0,
  });

//   const [selectValues, setSelectValues] = useState({
//     country:"",
//     industry:"",
//   })

  const handleInputChange = (e) => {
    const { name, value, checked, type } = e.target;
    const checkbox_value = checked ? 1 : 0
    setTxtValues({ ...txtValues, [name]: type === "checkbox" ? checkbox_value : value });
  };

//   const handleSelectChange = e => {
//     setSelectValues({ ...selectValues, [e.name]: e.id })
//   }

  const sendData = async () => {
    setSending(true);
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    // const data = {...txtValues, ...selectValues}
    const data = {...txtValues}

    const body = JSON.stringify({ ...data });

    try {
      const res = await axios.post(
        `${process.env.GATSBY_API_URL}/register-training-course`,
        body,
        config
      );

      if (res.data.status) {
        resetValues()
        navigate("/lp/thank-you-form/", {state: { details: "The form was successfully submitted. One of our sales representative will get in touch with you soon to confirm your registration." }})
      } else {
        setErrors(res.data.errors);
      }
      
      setSending(false);
    } catch (err) {
      setSending(false);
      setErrors({send_mail:1});
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    sendData();
  };

  const handleClose = () => {
    setErrors({});
  };

  const resetValues = ()=> {
    setErrors({});
    setTxtValues({
      name: "",
      company: "",
      email: "",
      course1:0,
      course2:0,
      course3:0,
      course4:0,
      course5:0,
      course6:0,
    });
  }

  return (
    <Fragment>
      {sending && <Loading message="Please wait while we are processing your request." />}
      {errors.send_mail && <ErrorMessage handleClose={handleClose} />}
      <Seo
        title='VR Course Registration | VIRSAT - Virtual Reality Safety Training'
        description='Register your interest. We have courses available for your.'
      />
      
      <div className="main-container newsletter-lp">
        <div className="newsletterlp-bg">
          <StaticImage
            src='../../assets/img/pages/newsletter/virsat-newsletter-background.jpg'
            alt='VIRSAT VR Courses Background'
            placeholder='blurred'
            objectFit
            quality="100"
          />
        </div>
        <div className="row training-courses" data-aos='zoom-in' data-aos-duration='600'>
        <div className="column show-overflow">
                <div className="form-bg-container">
                <StaticImage
                    src='../../assets/img/pages/training-courses/VR-training-registration-photo.jpg'
                    alt='VR Form Background'
                    placeholder='blurred'
                    objectFit
                    quality="100"
                    style={{borderRadius:"15px"}}
                    imgStyle={{borderRadius:"15px"}}
                />
                </div>
                
                <div className="logo-container">
                <Link to="/">
                    <img src={logo} alt="VIRSAT Logo" title="VIRSAT Logo"/>
                </Link>
                </div>
                <div className="form-container training-courses">
                <h1 className="main-title small light">VR Course Registration</h1>
                <p className="light description justify">Please fill up the form below to register your interest.</p>
                <form action='' method='post' onSubmit={handleSubmit}>
                    <div className='label-container'>
                    <TextBox
                        type='text'
                        id='name'
                        name='name'
                        placeholder='Your Name*'
                        txtBoxClass='regular-text'
                        onChange={handleInputChange}
                        value={txtValues.name}
                        required
                        error={errors.name}
                    />
                    {errors.name && (
                        <div className='field-error secondary-color'>{errors.name[0]}</div>
                    )}
                    </div>
                    <div className='label-container'>
                    <TextBox
                        type='text'
                        id='company'
                        name='company'
                        placeholder='Company Name*'
                        txtBoxClass='regular-text'
                        onChange={handleInputChange}
                        value={txtValues.company}
                        required
                        error={errors.company}
                    />
                    {errors.company && (
                        <div className='field-error secondary-color'>{errors.company[0]}</div>
                    )}
                    </div>
                    <div className='label-container'>
                    <TextBox
                        type='email'
                        id='email'
                        name='email'
                        placeholder='Company Email*'
                        txtBoxClass='regular-text'
                        onChange={handleInputChange}
                        value={txtValues.email}
                        required
                        error={errors.email}
                    />
                    {errors.email && (
                        <div className='field-error secondary-color'>{errors.email[0]}</div>
                    )}
                    </div>
                    <div className='label-container'>
                        <PhoneNumber
                        name='mobile'
                        id='mobile'
                        value={txtValues.mobile}
                        onChange={(mobile) =>
                            setTxtValues({ ...txtValues, mobile: mobile })
                        }
                        txtBoxClass='regular-text'
                        />
                        {errors.mobile && (
                        <div className='field-error'>{errors.mobile[0]}</div>
                        )}
                    </div>
                    <div>
                      <h3 style={{"color": "white", "marginBottom": "10px"}}>Courses offered:</h3>
                      {errors.courses && (
                        <div className='field-error secondary-color'>{errors.courses}</div>
                      )}
                    </div>
                    <div className='courses-offered-list'>
                      <div>
                        <div className="checkbox-container" style={{"marginBottom": "10px"}}>
                          <CheckBox
                              name="course1"
                              value={txtValues.course1}
                              onChange={handleInputChange}
                              checkboxBgClass="with-border"
                          />
                          <label htmlFor="course1" className="light small">VR Road Safety Awareness</label>
                        </div>
                        <div className="checkbox-container" style={{"marginBottom": "10px"}}>
                          <CheckBox
                              name="course2"
                              value={txtValues.course2}
                              onChange={handleInputChange}
                              checkboxBgClass="with-border"
                          />
                          <label htmlFor="course2" className="light small">VR Driving on Blacktop Roads</label>
                        </div>
                        <div className="checkbox-container" style={{"marginBottom": "10px"}}>
                          <CheckBox
                              name="course3"
                              value={txtValues.course3}
                              onChange={handleInputChange}
                              checkboxBgClass="with-border"
                          />
                          <label htmlFor="course3" className="light small">VR Driving on Graded Roads</label>
                        </div>
                      </div>
                      <div>
                        <div className="checkbox-container" style={{"marginBottom": "10px"}}>
                          <CheckBox
                              name="course4"
                              value={txtValues.course4}
                              onChange={handleInputChange}
                              checkboxBgClass="with-border"
                          />
                          <label htmlFor="course4" className="light small">Driving Simulator on Graded Roads</label>
                        </div>
                        <div className="checkbox-container" style={{"marginBottom": "10px"}}>
                          <CheckBox
                              name="course5"
                              value={txtValues.course5}
                              onChange={handleInputChange}
                              checkboxBgClass="with-border"
                          />
                          <label htmlFor="course5" className="light small">Life Saving Rules ADNOC standard</label>
                        </div>
                        <div className="checkbox-container" style={{"marginBottom": "10px"}}>
                          <CheckBox
                              name="course6"
                              value={txtValues.course6}
                              onChange={handleInputChange}
                              checkboxBgClass="with-border"
                          />
                          <label htmlFor="course6" className="light small">Life Saving Rules IOGP standard</label>
                        </div>
                      </div>
                    </div>
                    
                    
                    
                    <button type='submit' className="full-width" style={{marginTop:'10px'}}>Submit</button>

                    {/* <p className="light justify privacy-policy">We hate spam and promise to keep your email address safe. Check out our <Link to="/privacy-policy/" className="secondary"><strong>Privacy Policy</strong></Link>.</p> */}
                </form>
                </div>
            </div>
        </div>
      </div>
    </Fragment>
  )
}

export default CourseRegistration
