import React from "react"

const CheckBox = ({ value, onChange, name, label, divStyle = {}, checkboxBgClass }) => {
  return (
    <>
      <div style={divStyle} className={`checkbox-container`}>
        {label ? <span id="checkboxLabel">{label}</span> : ""}

        <label className="checkbox" htmlFor={name}>
          <input
            type="checkbox"
            checked={value}
            name={name}
            id={name}
            onChange={onChange}
          />
          <div className={`checkbox-bg ${checkboxBgClass}`}></div>
          <i className="indicator"></i>
        </label>
      </div>
    </>
  )
}

export default CheckBox
